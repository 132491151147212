import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Flex} from "antd";
import Introduce from "../../../components/Fun/Introduce/Introduce";
import qs from "qs";
import axios from "axios";
import {DOMAIN} from "../../../constant";
import Type2 from "../../../components/Fun/Type/Type2";
import {nanoid} from "nanoid";
import Header2 from "../../../components/Header/header2";

//定义UI组件
class OtherArchives extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                titleImg: {},
                introduce: {},
                allEvent: {},
            },
        };
    }

    componentDidMount() {
        const {search} = this.props.location
        const {type} = qs.parse(search.slice(1))
        axios.get(DOMAIN + '/api/official/archivesType/' + type)
            .then(r => {
                const data = r.data.data
                this.setState({data})
                console.log('archivesRoom成功=>', r.data.data)
            })
            .catch(e => {
                console.log('archivesRoom失败=>', e)
            })

    }


    render() {

        const {titleImg, introduce, allEvent} = this.state.data

        const allArr = Object.entries(allEvent)

        const toEvent = (id) => {
            window.open("/archives?id=" + id)
        }

        return (
            <div>
                <Header2/>

                <Flex justify={"center"} align={"center"} vertical={true}>

                    <Flex className={'sectionHeader bgc'} align={"center"} justify={"center"}
                          style={{backgroundImage: "url(" + titleImg.resource + ")"}}>
                        <h1>{titleImg.text}</h1>
                    </Flex>
                    <Flex style={{width: '100%'}} justify={"flex-end"}>
                        <div>
                            本页图
                        </div>
                    </Flex>
                    <Introduce content={introduce.text}/>

                    {
                        allArr.map(e => {
                            return <Type2 key={nanoid()} title={e[0]} allArr={Object.entries(e[1])} toEvent={toEvent}/>
                        })
                    }
                </Flex>
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(OtherArchives)
