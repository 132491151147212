import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Flex} from "antd";
import Introduce from "../../../components/Fun/Introduce/Introduce";
import axios from "axios";
import {DOMAIN} from "../../../constant";
import {nanoid} from "nanoid";
import dayjs from "dayjs";
import './stageArchives.css'
import Type2 from "../../../components/Fun/Type/Type2";
import Header2 from "../../../components/Header/header2";

//定义UI组件
class StageArchives extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                titleImg: {},
                introduce: {},
                allStage: {},
                seriesStage: {},
            },
        };
    }

    componentDidMount() {
        axios.get(DOMAIN + '/api/official/archivesType/0')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('archivesRoom成功=>', r.data.data)
            })
            .catch(e => {
                console.log('archivesRoom失败=>', e)
            })
    }

    render() {

        const {
            titleImg,
            introduce,
            allStage,
            seriesStage,
        } = this.state.data

        const allArr = Object.entries(allStage)
        const seriesArr = Object.entries(seriesStage)

        const toEvent = (id) => {
            this.props.history.push("/archives?id=" + id)
        }

        return (
            <div>
                <Header2/>

                <Flex justify={"center"} align={"center"} vertical={true}>

                    <Flex className={'sectionHeader bgc'} align={"center"} justify={"center"}
                          style={{backgroundImage: "url(" + titleImg.resource + ")"}}>
                        <h1>{titleImg.text}</h1>
                    </Flex>
                    <Flex style={{width: '100%'}} justify={"flex-end"}>
                        <div>
                            本页图
                        </div>
                    </Flex>
                    <Introduce content={introduce.text}/>
                    <Type2 title={'所有的舞台'} allArr={allArr} toEvent={toEvent}/>

                    {
                        seriesArr.map(o => {
                            console.log(o)
                            let k = o[0]
                            let v = o[1]
                            return <Flex key={nanoid()} className={"seriesStage"} vertical={true} justify={"center"}
                                         align={"center"}>
                                <Flex style={{height: '20vh', width: '100%'}} justify={"center"} align={"center"}
                                      vertical={true}>
                                    <div style={{width: "70%", borderBottom: "2px solid gray", paddingBottom: '20px'}}>
                                        <h1>{k}</h1>
                                        {/*<Flex>系列舞台简介</Flex>*/}
                                    </div>
                                </Flex>
                                <Flex style={{width: '60%'}} justify={"space-evenly"} align={"center"}
                                      wrap={"wrap"}>
                                    {v.map(o => {
                                        return <Flex key={nanoid()} style={{width: "300px"}} vertical={true}>
                                            <img className={"stageImg"} src={o.resource} onClick={() => toEvent(o.id)}/>
                                            <h1>{o.title}</h1>
                                            <h2>{dayjs.unix(o.time / 1000).format("YYYY-MM-DD")}</h2>
                                            <div dangerouslySetInnerHTML={{__html: o.point}}></div>
                                        </Flex>
                                    })}
                                </Flex>
                            </Flex>
                        })
                    }
                </Flex>
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(StageArchives)
