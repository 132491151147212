import React, {Component, useEffect} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import dayjs from "dayjs";
import {Flex} from "antd";

//定义UI组件
class CosmicCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {},
            seconds:""
        };
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({seconds:dayjs().format("HH:mm:ss")});
    }

    render() {

        const {seconds} = this.state

        function formatDate(date) {
            const year = String(date.getFullYear()).padStart(4, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        }

        const debut = () => {
            return Math.ceil(Math.abs(new Date().getTime() - new Date('2021-04-03').getTime()) / (1000 * 3600 * 24))
        }
        const cosmicCalendar = () => {
            const date = new Date('0000-01-01');
            date.setDate(date.getDate() + debut())
            return formatDate(date)
        }

        const promote = () => {
            return Math.ceil(Math.abs(new Date().getTime() - new Date('2021-08-15').getTime()) / (1000 * 3600 * 24))
        }

        return (
            <Flex vertical={true} align={"center"} justify={"center"} className={"recordBox"}>
                <p>公元{dayjs().format("YYYY-MM-DD")}</p>
                <p>宇宙历{cosmicCalendar()}</p>
                <p style={{fontSize: "30px"}}>{seconds}</p>
                <p>出道{debut()}天</p>
                <p>升格{promote()}天</p>
            </Flex>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(CosmicCalendar)
