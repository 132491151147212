import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Flex, Statistic} from 'antd';

import bg from './595064e8e5531.jpg'
import CosmicCalendar from "./CosmicCalendar/CosmicCalendar";
import axios from "axios";
import {DOMAIN} from "../../constant";

//定义UI组件
class RecentItinerary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            hide: true,
            data: {}
        };
    }

    componentDidMount() {
        document.title = "未知"
        axios.get(DOMAIN + '/api/official/recentItinerary')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('RecentItinerary成功=>', r.data.data)
            })
            .catch(e => {
                console.log('RecentItinerary失败=>', e)
            })
    }


    render() {

        const {hide,data} = this.state

        const {Countdown} = Statistic;

        const deadline = 1869840000000; // Dayjs is also OK

        const show = () => {
            this.setState({hide: !this.state.hide})
        }

        return (
            <Flex justify={'center'} align={"center"} vertical={true} style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: "100vh",
                color:"grey"
            }}>
                <CosmicCalendar/>
                <Flex vertical={true} align={"center"} justify={"center"} className={"recordBox"} dangerouslySetInnerHTML={{__html:data.text}}>

                </Flex>
                <Flex onClick={show} style={{width: '100%', minHeight: '120px'}} align={"center"} justify={"center"}>
                    <div hidden={hide} className={"sad-text"}>
                        <div>距离飞船驶入黑洞还剩</div>
                        <Countdown value={deadline}
                                   onFinish={()=>{this.props.history.push("/unfound", {n:1})}}
                                   valueStyle={{color:"gray",fontSize:"39px"}}
                                   format="Y年 M月 D 天 H 时 m 分 s 秒"/>
                    </div>
                </Flex>
            </Flex>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(RecentItinerary)
