import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Button, Flex, notification, Statistic} from "antd";
import dayjs from "dayjs";
import {SmileOutlined} from '@ant-design/icons';

//定义UI组件
class Skip extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {type} = this.props.location.state
        notification.open({
            message: '致403号飞船全体',
            description: "超光速引擎启动成功，飞船已逃离"+(type===0?"未知生物识别":"黑洞引力")+"范围,危险警报已解除,感谢各位的努力，倒计时结束后将返回首页",
            duration: 10,
            placement: "topRight",
            icon: <SmileOutlined/>,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    }

    render() {

        const {Countdown} = Statistic;

        return (
            <Flex align={"center"} justify={"center"} style={{minHeight:"100vh"}} vertical={true}>
                <Countdown value={new dayjs() + 13 * 1000} onFinish={() => {
                    this.props.history.push("/")
                }} title={"首页返回倒计时"}  format="s 秒"/>
                <Button href={"/"}>立刻返回首页</Button>
            </Flex>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Skip)
