import React, {Component} from 'react'
import {Redirect, Route, Switch} from "react-router-dom";
import Home3 from "./pages/Home/home3";
import Home2 from "./pages/Home/home2";
import "./App.css"
import Letter from "./pages/Letter/letter";
import ArchivesRoom from "./pages/ArchivesRoom/archivesRoom";
import Thanks from "./pages/More/thanks";
import Feedback from "./pages/More/feedback";
import StageArchives from "./pages/ArchivesRoom/archivesType/stageArchives";
import OtherArchives from "./pages/ArchivesRoom/archivesType/otherArchives";
import Archives from "./pages/ArchivesRoom/archives/archives";
import More from "./pages/More/more";
import Event from "./pages/Event/event";
import RecentItinerary from "./pages/RecentItinerary/RecentItinerary";
import NewStar from "./pages/NewStar/newStar";
import TimeLine from "./components/Fun/TimeLine/TimeLine";
import Plane from "./components/Fun/Plane/plane";
import Crew from "./pages/Crew/Crew";
import Unfound from "./pages/Unfound/Unfound";
import Skip from "./pages/Unfound/Skip";
import Crush from "./pages/Unfound/Crush";
import pocket from "./pages/ArchivesRoom/pocket/pocket";

export default class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        };

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        const {width} = this.state
        if (window.location.pathname === '/' && ((width < 1000 && window.innerWidth >= 1000) || (width >= 1000 && window.innerWidth < 1000))) {
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
    }

    render() {

        const {width, height} = this.state
        let home = Home3
        if (width < 1000) {
            home = Home2
        }
        return (<div>
            <Switch>
                {/*如果使用/作为登录路径必须要开启严格模式，否则登录成功也不会跳转*/}
                <Route exact push path="/" component={home}/>
                <Route push path="/recentItinerary" component={RecentItinerary}/>
                <Route push path="/letter" component={Letter}/>
                <Route push path="/event" component={Event}/>
                <Route push path="/timeLine" component={TimeLine}/>
                <Route push path="/archivesRoom" component={ArchivesRoom}/>
                <Route push path="/stageArchives" component={StageArchives}/>
                <Route push path="/otherArchives" component={OtherArchives}/>
                <Route push path="/pocket" component={pocket}/>
                <Route push path="/archives" component={Archives}/>
                <Route push path="/crew" component={Crew}/>
                <Route push path="/newStar" component={NewStar}/>
                <Route push path="/more" component={More}/>
                <Route push path="/thanks" component={Thanks}/>
                <Route push path="/feedback" component={Feedback}/>
                <Route push path="/unfound" component={Unfound}/>
                <Route push path="/skip" component={Skip}/>
                <Route push path="/crush" component={Crush}/>


                <Route push path="/plane" component={Plane}/>
                <Redirect to="/unfound"/>
            </Switch>
        </div>);
    }

}