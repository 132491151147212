import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Flex} from "antd";
import ShockCard from "../Fun/ShockCard/shockCard";
import axios from "axios";
import {DOMAIN} from "../../constant";

//定义UI组件
class Header2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                subzones: [],
                headerBackGround: {},
            }
        };
    }

    componentDidMount() {
        axios.get(DOMAIN + '/api/official/header')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('header成功=>', r.data.data)
            })
            .catch(e => {
                console.log('header失败=>', e)
            })
    }

    render() {
        const {subzones, headerBackGround} = this.state.data
        //滑轮，右键，左键，双击左键，移入，移出，键盘事件
        return (
            <Flex className={'bgc'} align={"center"} justify={"center"} style={{
                minHeight: '390px',
                backgroundImage: "url(" + headerBackGround.resource + ")",
                paddingBottom: '60px'
            }}>
                <ShockCard subzones={subzones}/>
            </Flex>
        );
    }

}

//创建并暴露容器组件
export default connect()(Header2)