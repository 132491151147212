import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Flex} from "antd";
import axios from "axios";
import {DOMAIN} from "../../constant";
import Introduce from "../../components/Fun/Introduce/Introduce";
import NormalLink from "../../components/NormalLink/normalLink";
import Header2 from "../../components/Header/header2";

//定义UI组件
class More extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                titleImg: {},
                introduce: {},
                thanksOneImg: {},
                thanksTwoImg: {},
                feedbackImg: {},
            }
        };
    }

    componentDidMount() {
        document.title = "更多"
        axios.get(DOMAIN + '/api/official/more')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('more成功=>', r.data.data)
            })
            .catch(e => {
                console.log('more失败=>', e)
            })
    }


    render() {

        const {titleImg, introduce, thanksOneImg, thanksTwoImg, feedbackImg} = this.state.data

        return (
            <Flex vertical={true}>
                <Header2/>
                <Flex className={'sectionHeader3 bgc'} align={"center"} justify={"center"}
                      style={{backgroundImage: "url(" + titleImg.resource + ")"}}>
                    <div dangerouslySetInnerHTML={{__html: titleImg.text}}></div>
                </Flex>
                <Flex style={{width: '100%'}} justify={"flex-end"}>
                    <div>
                        本页图
                    </div>
                </Flex>
                <Introduce content={introduce.text}/>
                <NormalLink target={"_blank"} to={'/thanks?id=0'}>
                    <Flex className={'sectionHeader3 bgc'} align={"center"} justify={"center"}
                          style={{backgroundImage: "url(" + thanksOneImg.resource + ")"}}>
                        <div dangerouslySetInnerHTML={{__html: thanksOneImg.text}}></div>
                    </Flex>
                </NormalLink>
                <NormalLink target={"_blank"} to={'/thanks?id=1'}>
                    <Flex className={'sectionHeader3 bgc'} align={"center"} justify={"center"}
                          style={{backgroundImage: "url(" + thanksTwoImg.resource + ")"}}>
                        <div dangerouslySetInnerHTML={{__html: thanksTwoImg.text}}></div>
                    </Flex>
                </NormalLink>
                <NormalLink target={"_blank"} to={"/feedback"}>
                    <Flex className={'sectionHeader3 bgc'} align={"center"} justify={"center"}
                          style={{backgroundImage: "url(" + feedbackImg.resource + ")"}}>
                        <div dangerouslySetInnerHTML={{__html: feedbackImg.text}}></div>
                    </Flex>
                </NormalLink>
            </Flex>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(More)