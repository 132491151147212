import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'

import './rotateMusic.css'
import {Button, Flex, Slider} from "antd";
import axios from "axios";
import {DOMAIN} from "../../../constant";


//定义UI组件
class RotateMusic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            play: false,
            rotate: false,
            data: {
                rotateImg: {},
                musics: []
            }
        }
    }

    componentDidMount() {
        this.volume.volume = 0.5
        axios.get(DOMAIN + '/api/official/music')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('music成功=>', r.data.data)
            })
            .catch(e => {
                console.log('music失败=>', e)
            })
    }


    render() {
        // const {pic,music} = this.props
        const {play, open, rotate} = this.state
        const {rotateImg, musics} = this.state.data

        let musicNum = 0;
        const musicArr = musics

        const ctlAudio = () => {
            if (this.state.play) {
                this.volume.play()
                this.setState({play: !play, rotate: !rotate})

            } else {
                this.volume.pause()
                this.setState({play: !play, rotate: !rotate})
            }
        }

        const ctlClick = () => {
            this.volume.src = musicArr[0].resource
            this.musicLink.href = musicArr[0].link
            this.musicName.innerHTML = musicArr[0].text
            this.volume.play()
        }

        const ctlVolume = (value) => {
            this.volume.volume = value / 100
        }

        const changeMusic = () => {
            musicNum = musicNum + Math.floor(Math.random() * musicArr.length) + 1
            this.volume.src = musicArr[musicNum % musicArr.length].resource
            this.musicLink.href = musicArr[musicNum % musicArr.length].link
            this.musicName.innerHTML = musicArr[musicNum % musicArr.length].text
            this.volume.play()
        }

        return (
            <div className={'rotateBox'}>
                <Flex hidden={!open}>
                    <div>
                        <img hidden={rotate} className={"rotateMusicImg"} src={rotateImg.resource} onClick={ctlAudio}/>
                        <img hidden={!rotate} className={"rotateMusicImgStable"} src={rotateImg.resource}
                             onClick={ctlAudio}/>
                        <div className="scrolling-text minst">
                            <a target={"_blank"} ref={n => this.musicLink = n}>
                                <div ref={n => this.musicName = n}></div>
                            </a>
                        </div>
                    </div>
                    <audio
                        onEnded={changeMusic}
                        ref={n => this.volume = n}></audio>
                    <Flex className={"rmvml"} vertical={true}>
                        <div className={'volume'}>
                            <Slider vertical defaultValue={50} onChange={ctlVolume}/>
                        </div>
                        <div className={"rmvcmt"} onClick={changeMusic}>换一首</div>
                    </Flex>
                </Flex>

                <div hidden={open} className="scrolling-text" onClick={() => {
                    this.setState({open: true})
                }}>
                    <Button type="primary" danger onClick={ctlClick}>
                        <div style={{color: "white"}}>不要点击这里</div>
                    </Button>
                </div>

            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(RotateMusic)
