import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Button, Carousel, Flex, Tooltip} from "antd";
import './Type.css'
import {nanoid} from "nanoid";

//定义UI组件
class Type extends Component {

    constructor(props) {
        super(props);
        this.state = {
            v: []
        }
    }

    render() {

        const {title, allArr, toEvent} = this.props
        const {v} = this.state

        const choose = (k) => {
            const allMap = new Map(allArr)
            this.setState({v: allMap.get(k)})
        }

        return (
            <Flex style={{width: "60%", margin: '20px 0'}} align={"center"} vertical={true}>
                <Flex style={{width: "100%", margin: ' 20px 0'}} align={"center"} justify={"space-between"}>
                    <h1 style={{minWidth: "50%"}}>{title}</h1>
                    <Flex align={"center"} justify={"flex-start"} wrap={"wrap"}>
                        {allArr.map(o => {
                            return <Button className={"type2Button"} key={nanoid()} onClick={() => choose(o[0])}>{o[0]}</Button>
                        })}
                    </Flex>
                </Flex>

                <Flex className={"typeContent"} wrap={"wrap"} align={"flex-start"} justify={"space-evenly"}>
                    {v.length === 0 ? (<div></div>) : (v.map(o => {
                        return <Tooltip key={nanoid()} title={o.title}>
                            <div className={"type2Button2"} onClick={() => toEvent(o.id)}>{o.title}</div>
                        </Tooltip>
                    }))}
                </Flex>
            </Flex>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Type)
