import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import './carousel1.css'
import './font/iconfont.css'
import {nanoid} from "nanoid";
import {Flex, message, Tooltip} from "antd";

//定义UI组件
class Carousel1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {}
        };
    }

    componentDidUpdate(prevProps, prevState) {
        // 获取外层容器
        const carousel = document.querySelector('.c1carousel');
        const shell = document.querySelector('.c1boxs');
        // 获取所有子元素
        const cells = shell.querySelectorAll('.c1box');
        // 获取容器宽度
        const cellWidth = shell.offsetWidth;
        // 获取容器高度
        const cellHeight = shell.offsetHeight;
        // 设置子元素大小为容器高度
        const cellSize = cellHeight;
        // 子元素数量
        const cellCount = cells.length;
        // 计算半径
        /*数字要与box的高度一起动*/
        const radius = Math.round((cellSize / 1.8) / Math.tan(Math.PI / cellCount));
        // 计算每个子元素的角度
        const theta = 360 / cellCount;
        // 当前选中的子元素索引
        let selectedIndex = 0;


        function rotateshell() {
            // 计算旋转角度
            const angle = theta * selectedIndex * -1;
            // 设置容器的旋转和平移效果
            shell.style.transform = 'translateZ(' + -radius + 'px) ' + 'rotateX(' + -angle + 'deg)';
            cells.forEach((cell, index) => {
                // 移除选中样式
                cell.classList.remove('c1selected');
            });
            cells[selectedIndex].classList.add('c1selected');
        }

        function selectPrev() {
            // 选中上一个子元素
            if (selectedIndex > 0) {
                selectedIndex--;
            } else {
                message.info("你正在查看第一个档案")
            }
            // 旋转容器
            rotateshell();
        }

        function selectNext() {
            // 选中下一个子元素
            if (selectedIndex !== cellCount - 1) {
                selectedIndex++;
            } else {
                message.info("这是最后一个档案")
            }
            // 旋转容器
            rotateshell();
        }

        // 获取上一个按钮
        const prevButton = document.querySelector('.c1up');
        // 绑定点击事件
        prevButton.addEventListener('click', selectPrev);
        // 获取下一个按钮
        const nextButton = document.querySelector('.c1next');
        // 绑定点击事件
        nextButton.addEventListener('click', selectNext);

        function initshell() {
            carousel.style.height = cellHeight + cells.length * 23 + "px";
            carousel.style.paddingTop = 30 + cells.length * 13 + "px";
            cells.forEach((cell, i) => {
                // 计算每个子元素的角度
                const cellAngle = theta * i;
                // 设置每个子元素的旋转和平移效果
                cell.style.transform = 'rotateX(' + -cellAngle + 'deg) translateZ(' + radius + 'px)';
            });
            // 初始化旋转容器
            rotateshell();
        }

        // 调用初始化函数
        initshell();
    }

    render() {

        const {describe, title, content, resources} = this.props
        const {} = this.state

        return (
            <div className="c1shell">
                <div className="c1arrows">
                    <button className="c1up"><i className="iconfont icon-shangjiantou"></i></button>
                    <button className="c1next"><i className="iconfont icon-xiajiantou"></i></button>
                </div>

                <div className={"c1carousel"}>
                    <ol className={"c1boxs"}>
                        <li key={nanoid()} className={"c1box"}>
                            <Flex vertical={true}
                                  style={{width: "60%", height: "100%", paddingLeft: "10px", userSelect: "none"}}
                                  align={"flex-start"}>
                                <div className={"c1dec"} dangerouslySetInnerHTML={{__html:describe.text}}>
                                </div>
                                <div className={"c1title"} dangerouslySetInnerHTML={{__html:title.text}}></div>
                                <div className={"c1content"} dangerouslySetInnerHTML={{__html:content.text}}>
                                </div>
                            </Flex>
                        </li>
                        {
                            resources !== null && resources !== undefined && resources.length > 0 ? (resources.map(resource => {
                                return <li key={nanoid()} className={"c1box"}>
                                    <Tooltip placement={"leftBottom"}
                                             title={<div dangerouslySetInnerHTML={{__html: resource.text}}></div>}>
                                        <Flex style={{width: "100%"}} justify={"center"} align={"center"}>
                                            <h1>{resource.name}</h1>
                                        </Flex>
                                        <iframe className={'c1item'}
                                                src={'http://player.bilibili.com/player.html?bvid=BV1fC411475b&p=2&autoplay=0'}/>
                                    </Tooltip>
                                </li>
                            })) : (<div></div>)
                        }
                        {
                            resources !== null && resources !== undefined && resources.length > 0 ? (resources.map(resource => {
                                return <li key={nanoid()} className={"c1box"}>
                                    <h1>{resource.name}</h1>
                                    <iframe className={'c1item'}
                                            src={'http://player.bilibili.com/player.html?bvid=BV1fC411475b&p=2&autoplay=0'}/>
                                </li>
                            })) : (<div></div>)
                        }
                    </ol>
                </div>
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Carousel1)
