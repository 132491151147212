/**
 * 该文件是用于创建一个为Person组件服务的reducer，reducer的本质是一个函数
 * reducer函数会接到两个参数，分别是之前的状态(preState)和动作对象(action)
 */
export default function reducerPerson(preState = [{id:'01',name:'tom',age:10},{id:'02',name:'katie',age:10},{id:'03',name:'kate',age:10}], action) {
    //从action中获取type和data
    const {type, data} = action
    //根据type决定如何处理数据
    switch (type) {
        //加
        case 'add_person':
            return [data,...preState]
        //初始化
        default:
            return preState
    }
}