import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import './book2.css'
import HTMLFlipBook from "react-pageflip";
import {nanoid} from "nanoid";
import {Flex} from "antd";

const PageCover = React.forwardRef((props, ref) => {
    const {cover, children} = props

    return (
        <div ref={ref}>
            <div className="page page-cover" style={{backgroundImage: "url(" + cover + ")"}}>
                <h1 className={"b2title"}>{children}</h1>
            </div>
        </div>
    );
});

const PageCoverInside = React.forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <div className="page" style={{backgroundColor: "whitesmoke"}}>
            </div>
        </div>
    );
});

const Page = React.forwardRef((props, ref) => {

    const {name, children, number, total} = props
    return (
        <div className={"page " + (number % 2 !== 0 ? "b2left" : "b2right")} ref={ref}>
            <Flex vertical={true} style={{height: "100%"}} justify={"space-between"}>
                <Flex vertical={true} justify={"flex-start"} align={"flex-start"}>
                    <div style={{height: "30px",color:"grey"}}>{children === "<p></p>" ? undefined : name}</div>
                    <div dangerouslySetInnerHTML={{__html: children}}></div>
                </Flex>
                <Flex justify={number % 2 !== 0 ? "flex-end" : "flex-start"}
                      style={{height: "10px"}}>{children === "<p></p>" ? undefined : number + "/" + total}</Flex>
            </Flex>
        </div>
    );
});

//定义UI组件
class Book2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {},
            num: 0,
            pages: [],
        };
    }


    componentDidMount() {
        this.setState({num: 0})
    }

    componentDidUpdate(prevProps, prevState) {
        const {num} = this.state
        if (num === 0) {
            const container = document.getElementById('b2hide')
            const pageSize = container.offsetHeight - 80

            const pages = [];
            let currentPage = "";
            let currentHeight = 0;

            Array.from(container.children).forEach(child => {
                const height = child.offsetHeight;
                // 如果添加当前元素后超过页面大小，则开始新页
                if (currentHeight + height > pageSize) {
                    pages.push(currentPage);
                    currentPage = "";
                    currentHeight = 0;
                }
                currentPage += child.outerHTML;
                currentHeight += height;
            });

            // 添加最后一页
            if (currentPage.length > 0) {
                pages.push(currentPage);
            }

            if (pages.length % 2 !== 0) {
                pages.push("<p></p>")
            }

            container.style.display = "none"

            this.setState({pages, num: 1})
        }
    }

    render() {
        const {name, cover} = this.props
        const {pages} = this.state

        let total = pages.length
        if (pages[pages.length - 1] === "<p></p>") {
            total--
        }

        return (
            <HTMLFlipBook className={"Book2"} width={399} height={500}
                          size={"stretch"} minWidth={150} maxWidth={399} minHeight={150} maxHeight={500}
                          showCover={true}
                          maxShadowOpacity={0.3} flippingTime={1500} usePortrait={false} showPageCorners={false}>
                <PageCover cover={cover}>{name}</PageCover>
                <PageCoverInside></PageCoverInside>
                {pages.map((page, i) => {
                    return <Page key={nanoid()} name={name} number={i + 1} total={total}>{page}</Page>
                })}
                <PageCoverInside></PageCoverInside>
                <PageCover cover={cover}></PageCover>
            </HTMLFlipBook>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Book2)
