import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Button, Calendar, Flex, Input, Select} from "antd";
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import dayjs from "dayjs";

import './event.css'
import axios from "axios";
import {DOMAIN} from "../../constant";
import {nanoid} from "nanoid";
import Header2 from "../../components/Header/header2";

//定义UI组件
class Event extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            types: [],
            content: "",
            date: dayjs(),
            data: {
                titleImg: {},
                introduce: {}
            },
            events: []
        };
    }

    monthEventFind = () => {
        const {types, content, date} = this.state
        axios.post(DOMAIN + '/api/official/monthEventFind', {types, content, time: date})
            .then(r => {
                const data = r.data.data
                this.setState({events: data})
                console.log('monthEventFind成功=>', r.data.data)
            })
            .catch(e => {
                console.log('monthEventFind失败=>', e)
            })
    }

    componentDidMount() {
        document.title = "月历"
        axios.get(DOMAIN + '/api/official/monthEvent')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('monthEvent成功=>', r.data.data)
            })
            .catch(e => {
                console.log('monthEvent失败=>', e)
            })
        this.monthEventFind()
    }

    render() {
        const {date, events} = this.state
        const {titleImg, introduce} = this.state.data

        const changeType = (types) => {
            this.setState({types})
        }

        const changeContent = (e) => {
            this.setState({content: e.target.value})
        }

        const changeDate = (date) => {
            const {types, content} = this.state
            axios.post(DOMAIN + '/api/official/monthEventFind', {types, content, time: date})
                .then(r => {
                    const data = r.data.data
                    this.setState({events: data, date})
                    console.log('monthEventFind成功=>', r.data.data)
                })
                .catch(e => {
                    console.log('monthEventFind失败=>', e)
                })
        }

        const toEvent = (id) => {
            this.props.history.push("/archives?id=" + id)
        }


        return (
            <div>
                <Header2/>
                <Flex vertical={true} align={"center"} justify={"center"}>
                    <Flex className={'sectionHeader3 '} align={"center"} justify={"center"}
                          style={{backgroundImage: "url(" + titleImg.resource + ")"}}>
                        <div dangerouslySetInnerHTML={{__html: titleImg.text}}></div>
                    </Flex>
                    {/*<Introduce content={introduce.text}/>*/}

                    <Flex vertical={true} wrap={"wrap"} style={{padding: "20px 0 0", backgroundColor: "#ffffff"}}>
                        <Flex align={"center"} justify={"space-evenly"}>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{width: '30%'}}
                                placeholder="请选择物料分类"
                                onChange={changeType}
                                options={[
                                    {label: "精彩舞台", value: 0},
                                    {label: "直播mc", value: 1},
                                    {label: "社交平台", value: 2}
                                ]}
                            />
                            <Input onChange={changeContent} placeholder="请输入关键字" style={{width: '30%'}}/>
                            <Button onClick={this.monthEventFind}>搜索</Button>
                        </Flex>
                        <Flex wrap={"wrap"} justify={"center"}>
                            <Flex className={"eventCalendar"} justify={"center"}>
                                <Calendar value={date} locale={locale} style={{margin: '50px'}} onChange={changeDate}/>
                            </Flex>

                            <Flex className={'events'} justify={"flex-start"} vertical={true}>
                                <div style={{borderBottom: "3px #5BC2E7 solid"}}></div>
                                {events.map(event => {
                                    return <div key={nanoid()} className={"event"}
                                                onClick={() => toEvent(event.id)}>{event.title}</div>
                                })}
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex justify={"center"}>
                        <Button target={"_blank"} href={"/timeLine"} className={"elementToEvent"}>
                            如想查看重要事件，请点此处前往——>大事记
                        </Button>
                    </Flex>
                </Flex>
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Event)
