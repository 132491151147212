import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Flex, Statistic} from "antd";
import dayjs from "dayjs";
import './crush.css'

//定义UI组件
class Crush extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hide: true
        }
    }

    componentDidMount() {
        // 文本输入与删除的速度(间隔多少毫秒输入/删除一个字符)
        const writeSpeed = 200

        // 获取文本元素
        const text = document.querySelector('.text')

        // 获取文本内容
        const textContent = text.innerHTML
        const textContentLen = textContent.length

        // 初始文本内容为空
        text.innerHTML = ''

        // 要写入字符的索引
        let idx = 0

        // 保存定时器
        let writeTimer = null

        const show = () => {
            this.setState({hide: false})
        }

        // 定时写入字符处理函数
        function writing() {
            let c = textContent[idx++]
            if (c === '/') {
                text.innerHTML += "<br/>"
            } else {
                text.innerHTML += c
            }
            if (idx >= textContentLen) {
                // 写完清除定时器
                clearInterval(writeTimer)
                show()
            }
        }

        // 定时写入一个字符
        writeTimer = setInterval(writing, writeSpeed)
    }


    render() {
        const {typeArr} = this.props.location.state
        const {hide} = this.state
        const {Countdown} = Statistic;

        return (
            <Flex vertical={true} align={"center"}>
                <Flex style={{width: "50%"}}>
                    <p className="text">
                        致403号飞船全体:      /
                        非常遗憾要告诉大家一个坏消息。      /
                        由于没有在正确的时机启动超光速引擎，
                        我们的飞船已被{typeArr[0]}捕获，
                        403号飞船即将解体。      /
                        请各位立刻放下手中的工作，
                        着手执行逃生程序。      /
                        很高兴能与大家一起探索星海的奥秘，
                        能与你们共事是我的荣幸。      /
                        {typeArr[1]}      /
                        如果...      /
                        如果各位有幸逃出生天。      /
                        请不要忘记你曾经是403号飞船的一份子。      /
                        因为遗忘——      /
                        就是背叛！！！
                    </p>
                </Flex>
                <div style={{display: hide ? ("none") : ("block")}}>
                    <Countdown title={"距离飞船解体还有"} value={new dayjs() + 3 * 60 * 1000} onFinish={() => {
                        this.props.history.push("/crush")
                    }}
                               valueStyle={{color: "red", fontSize: "39px"}}
                               format="m 分 s 秒"/>
                </div>

            </Flex>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Crush)
