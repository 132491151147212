import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Button, Calendar, Flex, Input, Select} from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import dayjs from "dayjs";

//定义UI组件
class search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hide: true,
            type: "",
            date: dayjs().subtract(2, 'day'),
            content: ""
        };
    }

    render() {

        let {hide, date, content} = this.state
        const {searchMessage,close} = this.props

        const changeType = (type) => {
            if (type === "TEXT") {
                this.setState({hide: false})
            } else {
                this.setState({hide: true})
            }
            this.setState({type})
        }

        const changeContent = (e) => {
            this.setState({content: e.target.value})
        }

        const changeDate = (date) => {
            this.setState({date})
            search(date)
        }

        const search = (time) => {
            if (time!==undefined){
                date=time
            }
            searchMessage(this.state.type, content, date)
            close()
        }

        return (
            <div>
                <Flex vertical={true} style={{padding: "0 0 0", maxHeight: "100vh"}}>
                    <Flex align={"center"} justify={"space-evenly"}>
                        <Select
                            allowClear
                            style={{width: '30%'}}
                            placeholder="请选择查询消息的类型"
                            onChange={changeType}
                            options={[
                                {label: "文字", value: "TEXT"},
                                {label: "音频", value: "AUDIO"},
                                {label: "图片", value: "IMAGE"},
                                {label: "视频", value: "VIDEO"},
                            ]}
                        />
                        <Input hidden={hide} onChange={changeContent} placeholder="请输入关键字" defaultValue={content}
                               style={{width: '30%'}}/>
                        <Button onClick={()=>{search()}}>搜索</Button>
                    </Flex>
                    <Flex justify={"center"}>
                        <Calendar value={date} locale={locale} style={{margin: '50px'}} onChange={changeDate}/>
                    </Flex>
                </Flex>

            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(search)
