import React, {Component} from 'react';
import {connect} from "react-redux";
import './Extend.css'

class Extend extends Component {

    render() {

        const {content,onClick,className} = this.props

        return (
            <button  onClick={onClick} className={"extend "+className}>
                <span>{content}</span>
            </button>
        );
    }
}

//创建并暴露容器组件
export default connect(
    state => ({token: state.token}), null)
(Extend)
