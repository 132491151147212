import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Button as AButton, DatePicker, Flex, Form, Input, message, Select} from 'antd';
import {MailOutlined} from '@ant-design/icons';
import {nanoid} from 'nanoid';

import Header2 from "../../components/Header/header2";
import {Button} from "rsuite";
import styled from "styled-components";
import {Bilibili, Tiktok, Weibo} from "@styled-icons/fa-brands";
import Introduce from "../../components/Fun/Introduce/Introduce";
import axios from "axios";
import {DOMAIN} from "../../constant";

const B = styled(Bilibili)`color: #FF8CB0;
  width: 30px;
  height: 30px;
`
const WB = styled(Weibo)`color: red;
  width: 30px;
  height: 30px;
`
const TK = styled(Tiktok)`color: black;
  width: 30px;
  height: 30px;
`

//定义UI组件
class Feedback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                titleImg: {},
                platforms: [],
                introduce: {},
            }
        };
    }

    componentDidMount() {
        document.title = "反馈"
        axios.get(DOMAIN + '/api/official/feedback')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('feedback成功=>', r.data.data)
            })
            .catch(e => {
                console.log('feedback失败=>', e)
            })
    }

    render() {
        const {TextArea} = Input;
        const {titleImg, platforms, introduce} = this.state.data

        const buttonType = (type, text, link) => {
            switch (type) {
                case 'weibo':
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<WB/>}
                                   key={nanoid()}><span dangerouslySetInnerHTML={{__html:text}}></span></Button>
                case 'bilibili':
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<B/>}
                                   key={nanoid()}><span dangerouslySetInnerHTML={{__html:text}}></span></Button>
                case 'tiktok':
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<TK/>}
                                   key={nanoid()}><span dangerouslySetInnerHTML={{__html:text}}></span></Button>
                default :
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<MailOutlined/>}
                                   key={nanoid()}><span dangerouslySetInnerHTML={{__html:text}}></span></Button>
            }
        }

        const submitForm = (v, type) => {
            let values = {...v, type, solve: 0}
            axios.post(DOMAIN + '/api/feedback/add', values)
                .then(r => {
                    if (type === 0) {
                        this.formOne.resetFields()
                    } else if (type === 1) {
                        this.formTwo.resetFields()
                    }
                    message.success("提交反馈成功")
                    // console.log('feedback添加成功=>', r.data)
                })
                .catch(e => {
                    console.log('feedback添加失败=>', e)
                })
            console.log(values)
        }

        return (
            <div >
                <Header2/>
                <Flex className={'sectionHeader bgc'} align={"center"} justify={"center"}
                      style={{backgroundImage: "url(" + titleImg.resource + ")"}}>
                    <div dangerouslySetInnerHTML={{__html: titleImg.text}}></div>
                </Flex>
                <Flex style={{width: '100%'}} justify={"flex-end"}>
                    <div>
                        本页图
                    </div>
                </Flex>
                <Introduce content={introduce.text}/>
                <Flex align={"center"} justify={"center"} className={'pbGroup bgc'}>
                    {platforms.map(p => {
                        return buttonType(p.remark, p.text, p.link)
                    })}
                </Flex>

                <Flex justify={"center"} vertical={true} style={{backgroundColor:"white"}}>
                    <Flex justify={"center"}>
                        <h1>意见反馈</h1>
                    </Flex>
                    <Form
                        ref={f => this.formOne = f}
                        onFinish={(values) => submitForm(values, 0)}
                        labelCol={{span: 6}}
                        wrapperCol={{span: 13}}
                        style={{margin: '20px'}}
                    >
                        <Form.Item name={"name"} rules={[{required: true, message: '请输入您的称呼'}]} label="称呼">
                            <Input placeholder="您希望我们如何称呼您？"/>
                        </Form.Item>
                        <Form.Item name={"email"} label="邮箱">
                            <Input placeholder={"我们将以最快的速度回复您的反馈！（非必填）"}/>
                        </Form.Item>
                        <Form.Item name={"other"} label="其他联系方式">
                            <Input placeholder={"或者留下其他社交平台的账号，如：微博，B站等（非必填）"}/>
                        </Form.Item>
                        <Form.Item name={"classify"} rules={[{required: true, message: '请选择反馈意见的类型'}]}
                                   label="分类">
                            <Select placeholder={"意见反馈类型"}>
                                <Select.Option value="00">资料错误</Select.Option>
                                <Select.Option value="01">授权问题</Select.Option>
                                <Select.Option value="02">其他建议</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={"link"} label="问题页面链接">
                            <Input placeholder={"问题页面的网址（非必填）"}/>
                        </Form.Item>
                        <Form.Item name={"detail"} rules={[{required: true, message: '请详细描述您的建议或意见'}]}
                                   label="详情">
                            <TextArea placeholder={"请详细描述您的建议或意见"} showCount maxLength={255} rows={3}/>
                        </Form.Item>
                        <Flex justify={"center"} align={"center"}>
                            <AButton type="primary" htmlType={"submit"}>
                                提交
                            </AButton>
                        </Flex>
                    </Form>
                </Flex>
                <Flex justify={"center"} vertical={true} style={{backgroundColor:"white"}}>
                    <Flex justify={"center"}>
                        <h1>档案补充</h1>
                    </Flex>
                    <Form
                        ref={f => this.formTwo = f}
                        onFinish={(values) => submitForm(values, 1)}
                        labelCol={{span: 6}}
                        wrapperCol={{span: 13}}
                        style={{margin: '20px'}}
                    >
                        <Form.Item name={"name"} rules={[{required: true, message: '请输入您的称呼'}]} label="称呼">
                            <Input placeholder="您希望我们如何称呼您？"/>
                        </Form.Item>
                        <Form.Item name={"email"} label="邮箱">
                            <Input placeholder={"我们将以最快的速度回复您的反馈！（非必填）"}/>
                        </Form.Item>
                        <Form.Item name={"other"} label="其他联系方式">
                            <Input placeholder={"或者留下其他社交平台的账号，如：微博，B站等（非必填）"}/>
                        </Form.Item>
                        <Form.Item name={"classify"} rules={[{required: true, message: '请选择补充物料的分类'}]}
                                   label="分类">
                            <Select placeholder={"物料的分类"}>
                                <Select.Option value="10">精彩舞台</Select.Option>
                                <Select.Option value="11">直播mc</Select.Option>
                                <Select.Option value="12">社交平台</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={"category"} rules={[{required: true, message: '请选择补充物料的类型'}]}
                                   label="类型">
                            <Select placeholder={"物料的类型"}>
                                <Select.Option value="0">视频</Select.Option>
                                <Select.Option value="1">音频</Select.Option>
                                <Select.Option value="2">图片</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={"time"} rules={[{required: true, message: '请选择物料发生的日期'}]}
                                   label="档案记录日期">
                            <DatePicker placeholder={"日期"}/>
                        </Form.Item>
                        <Form.Item name={"link"} rules={[{required: true, message: '请填写档案记录链接'}]}
                                   label="档案记录链接">
                            <Input placeholder={"物料的外站链接"}/>
                        </Form.Item>
                        <Form.Item name={"detail"} rules={[{required: true, message: '请详细描述您的建议或意见'}]}
                                   label="详情">
                            <TextArea placeholder={"请详细描述您要补充的物料"} showCount maxLength={255} rows={3}/>
                        </Form.Item>
                        <Flex justify={"center"} align={"center"}>
                            <AButton type="primary" htmlType={"submit"}>
                                提交
                            </AButton>
                        </Flex>
                    </Form>
                </Flex>
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Feedback)