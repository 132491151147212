import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Flex} from "antd";
import './Introduce.css'

//定义UI组件
class Introduce extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const {content} = this.props

        return (
            <Flex className={'Introduce'} justify={"center"} align={"center"} wrap={"wrap"}>
                <Flex className={'IntroduceTitle'} justify={"center"} align={"center"} vertical={true}>
                    <h5>SPACECRAFT INTRODUCTION</h5>
                    <span>本页介绍</span>
                </Flex>
                <Flex wrap={"wrap"}  vertical={true} className={'IntroduceContent'} justify={"center"} align={"center"} dangerouslySetInnerHTML={{__html: content}}></Flex>
            </Flex>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Introduce)
