// 引入react核心库
import React from "react";
// 引入ReactDOM
import ReactDOM from "react-dom/client"
//引入Provider
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";

//引入App组件
import App from './App'
import store from "./redux/store";
import {ConfigProvider} from "antd";

// const debounce = (fn, delay) => {
//     let timer = null;
//     return function () {
//         let context = this;
//         let args = arguments;
//         clearTimeout(timer);
//         timer = setTimeout(function () {
//             fn.apply(context, args);
//         }, delay);
//     }
// }
//
// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
//     constructor(callback) {
//         callback = debounce(callback, 10);
//         super(callback);
//     }
// }

// 渲染
ReactDOM.createRoot(document.getElementById('root')).render(
    <BrowserRouter>
        <ConfigProvider
            theme={{
                components: {
                    Layout: {
                        headerBg: 'transparent',
                        siderBg: 'transparent',
                        bodyBg: 'transparent',
                        footerBg: 'transparent'
                    },
                    Button: {
                        colorPrimary: '#16b8f3',
                        algorithm: true, // 启用算法
                    }
                },
            }}
        >
            <Provider store={store}>
                <App/>
            </Provider>
        </ConfigProvider>
    </BrowserRouter>
)