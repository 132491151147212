import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Carousel, Flex} from 'antd';
import {nanoid} from 'nanoid';
import axios from "axios";
import {DOMAIN} from "../../constant";
import {Button} from "rsuite";
import styled from 'styled-components'
import {Bilibili, Tiktok, Weibo} from '@styled-icons/fa-brands'
import './home3.css';
import Header2 from "../../components/Header/header2";
import NormalLink from "../../components/NormalLink/normalLink";
import RotateMusic from "../../components/Fun/RotateMusic/rotateMusic";
import CosmicCalendar from "../RecentItinerary/CosmicCalendar/CosmicCalendar";

const B = styled(Bilibili)`color: #FF8CB0;
  width: 30px;
  height: 30px;
`
const WB = styled(Weibo)`color: red;
  width: 30px;
  height: 30px;
`
const TK = styled(Tiktok)`color: black;
  width: 30px;
  height: 30px;
`

//定义UI组件
class Home3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                backgroundOne: {},
                portraitOne: {},
                carousels: [],
                randomWords: {},
                platformBackground: {},
                captainPlatforms: [],
                backgroundTwoOne: {},
                backgroundTwoTwo: {},
                fontTwo: {},
                portraitsTwo: [],
                three: [],
            }
        };
    }

    componentDidMount() {
        axios.get(DOMAIN + '/api/official/home')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('home成功=>', r.data.data)
            })
            .catch(e => {
                console.log('home失败=>', e)
            })
    }

    render() {

        const {
            backgroundOne,
            portraitOne,
            carousels,
            randomWords,
            platformBackground,
            captainPlatforms,
            backgroundTwoOne,
            backgroundTwoTwo,
            fontTwo,
            portraitsTwo,
            three,
        } = this.state.data

        const buttonType = (type, text, link) => {
            switch (type) {
                case 'weibo':
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<WB/>} key={nanoid()}>
                        <span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button>
                case 'bilibili':
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<B/>}
                                   key={nanoid()}><span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button>
                case 'tiktok':
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<TK/>}
                                   key={nanoid()}><span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button>
                default :
                    return <Button target={'_blank'} href={link} className={'pb'} key={nanoid()}>
                        <span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button>
            }
        }

        const randomWord = () => {
            axios.get(DOMAIN + '/api/official/randomWord')
                .then(r => {
                    const randomWords = r.data.data
                    this.setState({data: {...this.state.data, randomWords: randomWords}})
                    // console.log('randomWord成功=>', r.data.data)
                })
                .catch(e => {
                    console.log('randomWord失败=>', e)
                })
        }

        return (
            <div>
                <Header2/>
                <RotateMusic/>
                <Flex className={'bgc'} align={"center"} wrap={"wrap"} justify={"space-evenly"} style={{
                    backgroundImage: "url(" + backgroundOne.resource + ")",
                }}>
                    <img alt={"人像图"} style={{width: '30%'}} src={portraitOne.resource}/>
                    <div style={{width: '39%'}}>
                        <Carousel style={{width: '100%'}} autoplay>
                            {carousels.map(c => {
                                return <img src={c.resource} key={nanoid()}/>
                            })}
                        </Carousel>
                    </div>
                    <Flex style={{width: '30%'}} align={"center"} justify={"space-evenly"} wrap={"wrap"}>
                        <NormalLink to={"/recentItinerary"}>
                            <CosmicCalendar/>
                        </NormalLink>
                        <Flex vertical={true} align={"center"} justify={"center"} className={"recordBox"}
                              style={{userSelect: "none"}}
                              onClick={randomWord}>
                            <div dangerouslySetInnerHTML={{__html: randomWords.text}}></div>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex align={"center"} justify={"center"} className={'pbGroup bgc'} style={{
                    backgroundImage: "url(" + platformBackground.resource + ")",
                }}>
                    {captainPlatforms.map(p => {
                        return buttonType(p.remark, p.text, p.link)
                    })}
                </Flex>

                <Flex style={{
                    backgroundImage: "url(" + backgroundTwoOne.resource + ")",
                    minHeight: '700px'
                }}
                      align={"center"} justify={"center"} className={'bgc'}>

                    <Flex className={'bgc'} style={{
                        minHeight: '399px',
                        maxWidth: "60%",
                        backgroundImage: "url(" + backgroundTwoTwo.resource + ")",
                    }} justify={"center"} align={"center"}>
                        <Flex className={"recordBox"} style={{
                            background: "rgba(91, 194, 231, 0.6)",
                            minHeight: '250px',
                            width: "90%",
                            alignItems: "flex-start",
                            justifyContent: "flex-start"
                        }} dangerouslySetInnerHTML={{__html: fontTwo.text}} vertical={true}>
                        </Flex>
                    </Flex>
                    <Carousel style={{width: '399px'}} autoplay>
                        {portraitsTwo.map(p => {
                            return <img key={nanoid()} src={p.resource}/>
                        })}
                    </Carousel>
                </Flex>

                <Carousel autoplay>
                    {
                        three.map(t => {
                            return <div>
                                <div className={"special bgc"} style={{
                                    backgroundImage: "url(" + t.resource1 + ")"
                                }}>
                                    <Flex vertical={true} className={'block3font block3font1 recordBox'}
                                          dangerouslySetInnerHTML={{__html: t.resource2}}></Flex>
                                    <img className={'block3img block3img1'} src={t.resource3}/>
                                    <img className={'block3img block3img2'} src={t.resource4}/>
                                    <img className={'block3img block3img3'} src={t.resource5}/>
                                    <Flex vertical={true} className={'block3font block3font2 recordBox'}
                                          dangerouslySetInnerHTML={{__html: t.resource6}}></Flex>
                                </div>
                            </div>
                        })
                    }
                </Carousel>

            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Home3)
