import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'

import './shockCard.css'
import {nanoid} from "nanoid";
import NormalLink from "../../NormalLink/normalLink";
import {message} from "antd";


//定义UI组件
class ShockCard extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {subzones} = this.props

        return (
            <div className={"scshell"}>
                {subzones.map(sz => {
                    let link = sz.portrait.link
                    if (
                        link === '/archivesRoom' ||
                         link === '/crew' ||
                         link === '/newStar'
                    ) {
                        return <div className={"card"} key={nanoid()}>
                            <div className={"wrapper"}>
                                <img className={"cover-image"} src={sz.bottom.resource}/>
                            </div>
                            <div className={"character"} onClick={() => {
                                message.error("当前权限不足，请积累在船时间以晋升权限")
                            }}>
                                <img style={{width: "100%"}} src={sz.portrait.resource}/>
                            </div>
                        </div>
                    }
                    return <div className={"card"} key={nanoid()}>
                        <div className={"wrapper"}>
                            <img className={"cover-image"} src={sz.bottom.resource}/>
                        </div>
                        <NormalLink target={"_blank"} to={sz.portrait.link} className={"character"}>
                            <img style={{width: "100%"}} src={sz.portrait.resource}/>
                        </NormalLink>
                    </div>
                })}
            </div>
        );
    }

}

//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(ShockCard)