/**
 * 该文件用于汇总所有的reducer
 */
// 引入combineReducers用于汇总所有reducer
import {combineReducers} from "redux";

//引入对应的reducer
import count from './count'
import person from "./peroson";
//整合所有的reducer
export default combineReducers({
    count,
    person
})
