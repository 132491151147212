import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'

import './TimeLine.css'
import axios from "axios";
import {DOMAIN} from "../../../constant";
import dayjs from "dayjs";
import {nanoid} from "nanoid";
import {Button, Flex} from "antd";
import {Button as Button2} from "rsuite";
import styled from "styled-components";
import {Bilibili, Tiktok, Weibo} from "@styled-icons/fa-brands";

const B = styled(Bilibili)`color: #FF8CB0;
  width: 20px;
  height: 20px;
`
const WB = styled(Weibo)`color: red;
  width: 20px;
  height: 20px;
`
const TK = styled(Tiktok)`color: black;
  width: 20px;
  height: 20px;
`

//定义UI组件
class TimeLine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: []
        };
        axios.get(DOMAIN + '/api/official/bigEvent')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('bigEvent成功=>', r.data.data)
            })
            .catch(e => {
                console.log('bigEvent失败=>', e)
            })
    }

    componentDidMount() {
        document.title = "大事记"
    }


    componentDidUpdate(prevProps, prevState) {
        let selectors = {
            id: document.querySelector("#tlShell"),
            item: document.querySelectorAll("#tlShell .tlItem"),
            activeClass: "tlItem--active",
            img: ".tlImg"
        };
        // 将第一个时间轴项目激活，并设置时间轴背景图片为第一个项目的图片
        selectors.item[0].classList.add(selectors.activeClass);
        selectors.id.style.backgroundImage = `url(${selectors.item[0].querySelector(selectors.img).getAttribute("src")})`;
        // 获取时间轴项目的总数
        let timeLength = selectors.item.length;
        // 当页面滚动时触发滚动事件
        window.addEventListener("scroll", function () {
            let max, min;
            // 获取页面滚动距离
            let pos = window.scrollY;
            selectors.item.forEach(function (item, i) {
                // 获取当前时间轴项目的最小和最大高度
                min = item.offsetTop;
                max = item.offsetHeight + item.offsetTop;
                // 如果滚动到最后一个项目，并且超过了当前项目高度的一半，
                // 则将最后一个项目设置为激活状态，并设置背景图片为最后一个项目的图片
                if (i === timeLength - 2 && pos > min + item.offsetHeight / 2) {
                    selectors.item.forEach(function (item) {
                        item.classList.remove(selectors.activeClass);
                    });
                    selectors.id.style.backgroundImage = `url(${selectors.item[timeLength - 1].querySelector(selectors.img).getAttribute("src")})`;
                    selectors.item[timeLength - 1].classList.add(selectors.activeClass);
                }
                    // 如果当前滚动位置在当前项目的最小和最大高度之间
                // 则将当前项目设置为激活状态，并设置背景图片为当前项目的图片
                else if (pos <= max - 10 && pos >= min) {
                    selectors.id.style.backgroundImage = `url(${item.querySelector(selectors.img).getAttribute("src")})`;
                    selectors.item.forEach(function (item) {
                        item.classList.remove(selectors.activeClass);
                    });
                    item.classList.add(selectors.activeClass);
                }
            });
        });
    }


    render() {

        const {data} = this.state

        const toEvent = (id) => {
            this.props.history.push("/archives?id=" + id)
        }

        const buttonType = (type, text, link) => {
            switch (type) {
                case 0:
                    return <Button2 target={'_blank'} href={link} className={'apb'} startIcon={<B/>}
                                    key={nanoid()}><span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button2>
                case 1:
                    return <Button2 target={'_blank'} href={link} className={'apb'} startIcon={<WB/>} key={nanoid()}>
                        <span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button2>
                case 2:
                    return <Button2 target={'_blank'} href={link} className={'apb'} startIcon={<TK/>}
                                    key={nanoid()}><span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button2>
                default :
                    return <Button2 target={'_blank'} href={link} className={'apb'} key={nanoid()}>
                        <span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button2>
            }
        }

        return (
            <div>
                <div className={'tlShell bgc'} id={"tlShell"}>
                    <div className={'tlHeader'}>
                        <h2 className={'tlTitle'}>大事记</h2>
                        <h3 className={'tlSubTitle'}>船长偶像生涯的重要时刻</h3>
                    </div>
                    <div className={'timeline'}>
                        {data.map(d => {
                            return <div key={nanoid()} className={'tlItem'} data-text={d.title}>
                                <div className={'tlContent'}>
                                    <img src={d.resource} className={'tlImg'} onClick={() => toEvent(d.id)}/>
                                    <h2 className={'tl-content-title'}>{dayjs.unix(d.time / 1000).format("YYYY-MM-DD")}</h2>
                                    {buttonType(d.platform, d.name, d.link)}
                                    <div className={'tl-content-desc'}
                                         dangerouslySetInnerHTML={{__html: d.point}}></div>
                                </div>
                            </div>
                        })}

                        <div style={{minHeight: '190px'}}></div>
                    </div>
                    <Flex justify={"center"}>
                        <Button target={"_blank"} href={"/event"} className={"elementToEvent"}>
                            如想查看更多内容，请点此处前往——>月历
                        </Button>
                    </Flex>
                </div>
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(TimeLine)
