/**
 * 该文件专门用于暴露store，整个应用只有一个store对象
 */
//引入creeateStore,专门用于创建redux的核心store
// import {createStore} from "redux";
import {legacy_createStore as createStore} from 'redux'
//引入redux-thunk用于支持异步action
// import thunk from 'redux-thunk'

//引入汇总后的reducer
import allReducers from "./reducers";

//暴露store
export default createStore(allReducers);
