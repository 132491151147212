import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import './plane.css'
import {Flex} from "antd";
//定义UI组件
class Plane extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {}
        };
    }

    render() {

        const {} = this.state.data

        return (
            <Flex align={"center"} justify={"center"} style={{height:'100vh'}}>
                <div className="plane"></div>
            </Flex>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Plane)
