import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import './carousel2.css'
import '../2/font/iconfont.css'
import {nanoid} from "nanoid";
import {Flex, Modal} from "antd";
import Book2 from "../../Book/2/book2";
import {CloseCircleTwoTone} from "@ant-design/icons";

let startX = -1;
let endX = -1;
let prevFun;
let nextFun;

let num = 0;
let moveNum;

//定义UI组件
class carousel2_2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                name: "",
                cover: "",
                text: "",
            },
        };
    }

    componentDidUpdate(prevProps, prevState) {
        // const {resources} = this.props
        // 获取所有的item元素
        var items = document.getElementsByClassName('c2item');
        if (num === 0) {
            moveNum = items.length / 2
            num = 1;
        }

        // // 循环遍历每个item
        // for (var i = 0; i < items.length; i++) {
        //     // 获取当前item
        //     var item = items[i];
        //     var frame = item.getElementsByClassName('c2frame')[0];
        //     var frontBox = frame.getElementsByClassName('c2front')[0];
        //     var leftBox = frame.getElementsByClassName('c2left')[0];
        //     var rightBox = frame.getElementsByClassName('c2right')[0];
        //     // 设置背景图片
        //     frontBox.style.backgroundImage = 'url(' + resources[i].cover + ')';
        //     leftBox.style.backgroundImage = 'url(' + resources[i].cover + ')';
        //     rightBox.style.backgroundImage = 'url(' + resources[i].cover + ')';
        // }
        (function () {
            "use strict";
            var shell = document.getElementsByClassName('c2shell')[0];
            var slider = shell.getElementsByClassName('c2shell_slider')[0];
            var items = shell.getElementsByClassName('c2item');
            var prevBtn = shell.getElementsByClassName('c2prev')[0];
            var nextBtn = shell.getElementsByClassName('c2next')[0];
            // 定义变量
            var width, height, totalWidth, margin = 20,
                currIndex = 0,
                interval, intervalTime = 3000;

            function init() {
                // 初始化函数
                resize();
                move(Math.floor(moveNum));
                bindEvents();
            }

            function resize() {
                // 窗口大小变化时调整大小
                width = Math.max(document.documentElement.clientWidth * .20, 275);
                height = document.documentElement.clientHeight * .5;
                totalWidth = width * items.length;
                // 设置slider宽度
                slider.style.width = totalWidth + "px";
                // 设置每个item的宽度和高度
                for (var i = 0; i < items.length; i++) {
                    let item = items[i];
                    item.style.width = (width - (margin * 2)) + "px";
                    item.style.height = height + "px";
                }
            }

            function bindEvents() {
                // 窗口大小变化时调整大小
                window.onresize = resize;
                prevFun = prev;
                nextFun = next;
                // 点击prev按钮切换item
                prevBtn.addEventListener('click', () => {
                    prev();
                });
                nextBtn.addEventListener('click', () => {
                    next();
                });
            }

            init();

            function move(index) {
                // 移动shell到指定的item
                if (index < 1) index = items.length;
                if (index > items.length) index = 1;
                currIndex = index;
                moveNum = currIndex;
                // 遍历所有item
                for (var i = 0; i < items.length; i++) {
                    let item = items[i],
                        box = item.getElementsByClassName('c2frame')[0];
                    if (i == (index - 1)) {
                        // 当前item添加active类并设置3D效果
                        item.classList.add('c2item--active');
                        box.style.transform = "perspective(1200px)";
                    } else {
                        // 其他item移除active类并设置3D效果
                        item.classList.remove('c2item--active');
                        box.style.transform = "perspective(1200px) rotateY(" + (i < (index - 1) ? 40 : -40) + "deg)";
                    }
                }
                // 移动slider
                slider.style.transform = "translate3d(" + ((index * -width) + (width / 2) + window.innerWidth / 2) + "px, 0, 0)";
                // 设置body背景图片
                var frontBox = items[index - 1].getElementsByClassName('c2front')[0];
                shell.style.backgroundImage = frontBox.style.backgroundImage;
            }

            // 切换item
            function prev() {
                move(--currIndex);
            }

            function next() {
                move(++currIndex);
            }
        })();
    }

    // 手指触摸到屏幕
    handleTouchStart = (e) => {
        startX = e.touches[0].clientX
    }

    // 手指滑动
    handleTouchMove(e) {
        endX = e.touches[0].clientX
    }

    // 手指离开屏幕
    handleTouchEnd() {
        // 获取滑动范围
        if (startX > -1 && endX > -1) {
            let distance = Math.abs(startX - endX);
            if (distance > 20) {
                // 两个手指位置距离相差50px，即视为要滑动
                if (startX > endX) {
                    nextFun()
                } else {
                    prevFun()
                }
                startX = -1;
                endX = -1;
            } else {
                return;
            }
        }
    }

    render() {

        const {resources} = this.props
        const {open, data} = this.state

        return (
            <div className={"c2shell bgc"}
                 onTouchStart={this.handleTouchStart.bind(this)}
                 onTouchMove={this.handleTouchMove.bind(this)}
                 onTouchEnd={this.handleTouchEnd.bind(this)}>
                <div className="c2shell_body">
                    <div className="c2button">
                        <div className="c2prev"><i className="iconfont icon-backward_filled"></i></div>
                        <div className="c2next"><i className="iconfont icon-forward_filled"></i></div>
                    </div>
                    <div className="c2shell_slider">
                        {resources !== undefined && resources !== null && resources.length > 0 ? (
                            resources.map((resource, i) => {
                                return <a key={nanoid()} onClick={() => {
                                    moveNum = i + 1;

                                    this.setState({
                                        open: true,
                                        data: {name: resource.name, cover: resource.cover, text: resource.text}
                                    })
                                }}>
                                    <div className={"c2item"}>
                                        <div className={"c2frame"}>
                                            <div className={"c2box c2front bgc"}
                                                 style={{backgroundImage: 'url(' + resources[i].cover + ')'}}>
                                                <h1>{resource.name}</h1>
                                                {/*<span>-In the year 2018 I reached the age of 13-</span>*/}
                                            </div>
                                            <div className={"c2box c2left"}
                                                 style={{backgroundImage: 'url(' + resources[i].cover + ')'}}></div>
                                            <div className={"c2box c2right"}
                                                 style={{backgroundImage: 'url(' + resources[i].cover + ')'}}></div>
                                        </div>
                                    </div>
                                </a>
                            })
                        ) : (<div></div>)}
                    </div>
                </div>
                <Modal width={"auto"} style={{maxWidth: "80%"}} footer={null}
                       closeIcon={<CloseCircleTwoTone twoToneColor={"#16b8f3"}/>}
                       destroyOnClose={true} open={open} onCancel={() => {
                    this.setState({open: false})
                }}>
                    <Flex justify={"center"} align={"center"}>
                        <div id={'b2hide'} className={'page'} dangerouslySetInnerHTML={{__html: data.text}}></div>
                        <Book2 {...data}/>
                    </Flex>
                </Modal>
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(carousel2_2)
