import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Flex} from "antd";
import './archivesRoom.css'
import NormalLink from "../../components/NormalLink/normalLink";
import axios from "axios";
import {DOMAIN} from "../../constant";
import Header2 from "../../components/Header/header2";
import Introduce from "../../components/Fun/Introduce/Introduce";
import {nanoid} from "nanoid";
import Star from "../../components/Fun/Background/star/star";

//定义UI组件
class ArchivesRoom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                titleImg: {},
                introduce: {},
                typeImg: []
            },
        };
    }

    componentDidMount() {
        document.title = "档案室"
        axios.get(DOMAIN + '/api/official/archivesRoom')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                console.log('archivesRoom成功=>', r.data.data)
            })
            .catch(e => {
                console.log('archivesRoom失败=>', e)
            })
    }

    render() {

        const {titleImg, introduce, typeImg} = this.state.data

        return (
            <Flex vertical={true} justify={"center"}>
                <Header2/>
                <Flex className={'sectionHeader3 bgc'} align={"center"} justify={"center"}
                      style={{backgroundImage: "url(" + titleImg.resource + ")"}}>
                    <h1>飞船档案室</h1>
                </Flex>
                <Flex style={{width: '100%'}} justify={"flex-end"}>
                    <div>
                        本页图
                    </div>
                </Flex>
                <Introduce content={introduce.text}/>
                {/*<Star></Star>*/}
                <Flex justify={"space-evenly"} wrap={"wrap"} style={{marginTop: "30px", padding: "0 10%"}}>
                    {typeImg.map(t => {
                        return <NormalLink key={nanoid()} target={"_blank"} to={t.link}
                                           style={{width: "39%", marginBottom: "30px", backgroundColor: "white"}}>
                            <Flex vertical={true}>
                                <img className={"archivesImg"} src={t.resource}/>
                                <div className={"archivesName"} dangerouslySetInnerHTML={{__html: t.text}}></div>
                            </Flex>
                        </NormalLink>
                    })}
                </Flex>

            </Flex>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(ArchivesRoom)
