import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import './carousel3.css'
import {Flex, Popover, Slider, Tooltip} from "antd";
import {nanoid} from "nanoid";
import {
    LeftOutlined,
    NotificationTwoTone,
    PauseCircleTwoTone,
    PlayCircleTwoTone,
    RightOutlined
} from "@ant-design/icons";

//定义UI组件
class Carousel3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {},
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const slides = document.querySelectorAll(".c3item");
        const button = document.querySelectorAll(".c3button");

        const maxIndex = slides.length - 1;//当前图片的最大索引

        slides[0].classList.add("active");
        slides[1].classList.add("next");
        slides[maxIndex].classList.add("prev");

        let current = 0; // 初始化当前图片的索引变量
        let prev; // 前一张图片的索引变量
        let next; // 后一张图片的索引变量
        // 给所有按钮添加点击事件监听器
        for (let i = 0; i < button.length; i++) {
            button[i].addEventListener("click", () => i === 0 ? gotoPrev() : gotoNext());
        }
        // 切换到前一张图片
        const gotoPrev = () => current > 0 ? gotoNum(current - 1) : gotoNum(maxIndex);
        // 切换到后一张图片
        const gotoNext = () => current < maxIndex ? gotoNum(current + 1) : gotoNum(0);
        // 切换到指定索引的图片
        const gotoNum = number => {
            current = number;
            prev = current - 1;
            next = current + 1;
            // 移除所有图片的类名
            for (let i = 0; i < slides.length; i++) {
                slides[i].classList.remove("active");
                slides[i].classList.remove("active2");
                slides[i].classList.remove("prev");
                slides[i].classList.remove("next");
            }
            // 如果后一张图片的索引超过了图片数量，将其重置为0
            if (next == maxIndex + 1) {
                next = 0;
            }
            // 如果前一张图片的索引小于0，将其重置为最后一张图片的索引
            if (prev == -1) {
                prev = maxIndex;
            }
            // 给当前、前一张、后一张图片添加对应的类名
            slides[current].classList.add("active");
            slides[prev].classList.add("prev");
            slides[next].classList.add("next");

            //为音乐换源
            this.music.src = slides[current].querySelector('input').value
            this.play.hidden = false
            this.pause.hidden = true
            this.showTime.textContent = 0
        }

        // 该代码用于实现一个图片轮播的功能，首先获取到轮播容器的DO
        // M元素和所有的图片元素，以及左右切换按钮的DOM元素。然后定义
        // 了三个变量current、prev、next，分别用于记录当前显示图片的索
        // 引、前一张图片的索引和后一张图片的索引。接着给左右切换按钮
        // 添加点击事件监听器，当点击左按钮时执行gotoPrev函数，当点击
        // 右按钮时执行gotoNext函数。gotoPrev和gotoNext函数用于切换到
        // 前一张或后一张图片，gotoNum函数用于切换到指定索引的图片。
        // 在gotoNum函数中，首先更新current、prev、next变量的值，然后
        // 移除所有图片元素的类名，最后给当前、前一张和后一张图片添加
        // 对应的类名，用于控制它们的显示和隐藏。
    }

    render() {

        const {resources} = this.props
        const {} = this.state

        const content = <Slider vertical={true} defaultValue={50} style={{margin: 0, height: "100px"}}
                                onChange={(v) => {
                                    this.music.volume = v / 100
                                }}/>

        return (
            <Flex className={"c3content"} justify={"center"} align={"center"}>
                <Flex vertical={true} justify={"center"} align={"center"} className={"c3shell"}>

                    <audio ref={n => this.music = n}
                           onCanPlay={() => {
                               this.progress.max = this.music.duration.toString();
                               let duration = this.music.duration;
                               let value = this.music.currentTime;
                               this.showTime.textContent = Math.floor(value / 60) + ":" + String(Math.floor(value % 60)).padStart(2, '0') + "/" + Math.floor(duration / 60) + ":" + String(Math.floor(duration % 60)).padStart(2, '0');
                           }}
                           onTimeUpdate={() => {
                               this.progress.value = this.music.currentTime.toString()

                               let duration = this.music.duration;
                               let value = this.music.currentTime;
                               this.showTime.textContent = Math.floor(value / 60) + ":" + String(Math.floor(value % 60)).padStart(2, '0') + "/" + Math.floor(duration / 60) + ":" + String(Math.floor(duration % 60)).padStart(2, '0');
                           }}/>

                    {
                        resources !== null && resources !== undefined && resources.length > 0 ? (resources.map((resource, i) => {
                            if (i === 0) {
                                this.music.src = resource.resource;
                                this.music.volume = 0.5;
                            }
                            return <Flex vertical={true} key={nanoid()} className={"c3item"} align={"center"}
                                         justify={"center"}>
                                <input defaultValue={resource.resource} hidden={true}/>
                                <Tooltip placement={"left"}
                                         title={<div dangerouslySetInnerHTML={{__html: resource.text}}></div>}>
                                    <img src={resource.cover}/></Tooltip>
                                <Flex align={"center"} justify={"center"} style={{height: "20%"}} vertical={true}>
                                    <div className={"c3scrolling-text"}>
                                        <a target={"_blank"} href={resource.link}>
                                            <div>
                                                <h5>{resource.name}</h5>
                                            </div>
                                        </a>
                                    </div>

                                </Flex>
                            </Flex>
                        })) : (<div></div>)
                    }

                    <Flex align={"flex-end"} justify={"space-between"} className={"c3button-container"}>
                        <div className="c3button"><LeftOutlined/></div>

                        <Flex justify={"center"} align={"flex-end"}
                              style={{height: "100%", width: "100%", minWidth: "100px"}}>
                            <PlayCircleTwoTone
                                className={"c3iconSize"} ref={n => this.play = n}
                                twoToneColor={"#16b8f3"}
                                hidden={false}
                                onClick={() => {
                                    this.music.play();
                                    this.pause.hidden = false;
                                    this.play.hidden = true;
                                    document.getElementsByClassName("active")[0].classList.add("active2")
                                }}/>
                            <PauseCircleTwoTone
                                style={{
                                    // 使图标持续旋转
                                    animation: 'spin 6s linear infinite',
                                }}
                                className={"c3iconSize"} ref={n => this.pause = n}
                                twoToneColor={"#16b8f3"}
                                hidden={true}
                                onClick={() => {
                                    this.music.pause();
                                    this.play.hidden = false;
                                    this.pause.hidden = true;
                                    document.getElementsByClassName("active")[0].classList.remove("active2")

                                }}/>
                            <Popover content={content}>
                                <NotificationTwoTone className={"c3iconSize"} twoToneColor={"#16b8f3"}/>
                            </Popover>
                            <Flex style={{margin: 0, width: "70%"}}>
                                <input type={"range"} min={"0"} defaultValue={"0"}
                                       ref={n => this.progress = n}
                                       onChange={() => {
                                           let value = this.progress.value;
                                           let duration = this.progress.max;
                                           this.music.currentTime = value;
                                           this.showTime.textContent = Math.floor(value / 60) + ":" + String(Math.floor(value % 60)).padStart(2, '0') + "/" + Math.floor(duration / 60) + ":" + String(Math.floor(duration % 60)).padStart(2, '0');
                                       }}/>
                                <div ref={n => this.showTime = n}></div>
                            </Flex>
                        </Flex>

                        <div className="c3button"><RightOutlined/></div>
                    </Flex>

                </Flex>
            </Flex>
        );
    }

}

//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Carousel3)
