import React, {Component} from 'react';
//引入connect连接
import {nanoid} from 'nanoid';
import axios from "axios";
import {DOMAIN} from "../../constant";
import {Button} from "rsuite";
import styled from 'styled-components'
import {Bilibili, Tiktok, Weibo} from '@styled-icons/fa-brands'
import Carousel2_1 from "../../components/Fun/Carousel/2/carousel2_1";
import {Flex, Menu, message} from "antd";
import RotateMusic from "../../components/Fun/RotateMusic/rotateMusic";
import './home2.css'
import {connect} from "react-redux";

const B = styled(Bilibili)`color: #FF8CB0;
  width: 20px;
  height: 20px;
`
const WB = styled(Weibo)`color: red;
  width: 20px;
  height: 20px;
`
const TK = styled(Tiktok)`color: black;
  width: 20px;
  height: 20px;
`

//定义UI组件
class Home2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current: "/",
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                mobileBackgroundOne: {},
                signOne: {},
                platformBackground: {},
                captainPlatforms: [],
            },
            header: {
                subzones: [],
            }
        };
    }


    componentDidMount() {
        message.info('用高分辨率打开本网站体验更好哦~');
        axios.get(DOMAIN + '/api/official/home')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('home成功=>', r.data.data)
            })
            .catch(e => {
                console.log('home失败=>', e)
            })
        axios.get(DOMAIN + '/api/official/header')
            .then(r => {
                const header = r.data.data
                this.setState({header})
                // console.log('header成功=>', r.data.data)
            })
            .catch(e => {
                console.log('header失败=>', e)
            })
    }


    render() {

        const {current, data, header} = this.state
        const {mobileBackgroundOne, signOne, platformBackground, captainPlatforms} = data
        const {subzones} = header

        const items = [
            {
                label: '首页',
                key: '/',
            },
            {
                label: '信',
                key: '/letter',
            },
            {
                label: '大事记',
                key: '/timeLine',
            },
            // {
            //     label: '档案室',
            //     key: '/archivesRoom',
            // },
            // {
            //     label: '船员',
            //     key: '/crew',
            // },
            // {
            //     label: '新星球',
            //     key: '/newStar',
            // },
            {
                label: '更多',
                key: '/more',
            },
        ]

        const onClick = (e) => {
            this.setState({current: e.key})
            window.open(e.key, "_blank")
        };


        const buttonType = (type, text, link) => {
            switch (type) {
                case 'weibo':
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<WB/>} key={nanoid()}>
                        <span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button>
                case 'bilibili':
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<B/>}
                                   key={nanoid()}><span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button>
                case 'tiktok':
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<TK/>}
                                   key={nanoid()}><span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button>
                default :
                    return <Button target={'_blank'} href={link} className={'pb'} key={nanoid()}>
                        <span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button>
            }
        }

        return (
            <div>
                <div>飞船航行日志</div>
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items}/>
                <RotateMusic/>
                <Flex className={'bgc'} align={"center"} wrap={"wrap"} justify={"center"} style={{
                    backgroundImage: "url(" + mobileBackgroundOne.resource + ")", minWidth: "100%", minHeight: "900px"
                }}>
                    {signOne !== null ? (<img src={signOne.resource}/>) : (<div></div>)}
                </Flex>
                <Flex align={"center"} justify={"center"} vertical={true} className={'pbGroup bgc'} style={{
                    backgroundImage: "url(" + platformBackground.resource + ")",
                }}>
                    {captainPlatforms.map(p => {
                        return buttonType(p.remark, p.text, p.link)
                    })}
                </Flex>
                <Carousel2_1 resources={subzones}/>
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({
        count: state
    }), null)
(Home2)
