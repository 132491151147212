import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Flex} from 'antd';
import {nanoid} from 'nanoid';

import Header2 from "../../components/Header/header2";
import {Button} from "rsuite";
import styled from "styled-components";
import {Bilibili, Tiktok, Weibo} from "@styled-icons/fa-brands";
import Introduce from "../../components/Fun/Introduce/Introduce";
import axios from "axios";
import {DOMAIN} from "../../constant";
import qs from "qs";

const B = styled(Bilibili)`color: #FF8CB0;
  width: 30px;
  height: 30px;
`
const WB = styled(Weibo)`color: red;
  width: 30px;
  height: 30px;
`
const TK = styled(Tiktok)`color: black;
  width: 30px;
  height: 30px;
`

//定义UI组件
class Thanks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                titleImg: {},
                introduce: {},
                subzones: []
            }
        };
    }

    componentDidMount() {
        document.title = "致谢"
        const {search} = this.props.location
        const {id} = qs.parse(search.slice(1))
        axios.get(DOMAIN + '/api/official/thanks/' + id)
            .then(r => {
                const data = r.data.data
                this.setState({data})
                console.log('thanks成功=>', r.data.data)
            })
            .catch(e => {
                console.log('thanks失败=>', e)
            })
    }

    render() {

        const {titleImg, introduce, subzones} = this.state.data

        const buttonType = (type, text, link) => {
            switch (type) {
                case 1:
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<WB/>}
                                   key={nanoid()}>{text}</Button>
                case 0:
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<B/>}
                                   key={nanoid()}>{text}</Button>
                case 2:
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<TK/>}
                                   key={nanoid()}>{text}</Button>
                default :
                    return <Button target={'_blank'} href={link} className={'pb'} key={nanoid()}>{text}</Button>
            }
        }

        return (
            <div>
                <Header2/>
                <Flex className={'sectionHeader bgc'} align={"center"} justify={"center"}
                      style={{backgroundImage: "url(" + titleImg.resource + ")"}}>
                    <div dangerouslySetInnerHTML={{__html: titleImg.text}}></div>
                </Flex>
                <Flex style={{width: '100%'}} justify={"flex-end"}>
                    <div>
                        本页图
                    </div>
                </Flex>
                <Introduce content={introduce.text}/>
                {subzones.map(sz => {
                    return <Flex className={'bgc'} vertical={true} style={{paddingLeft: '20%', paddingBottom: '30px',backgroundColor :"white"}}>
                        <h1>{sz.title}</h1>
                        <Flex wrap={"wrap"}>
                            {sz.nameList.map(name => {
                                return buttonType(name.platform, name.name, name.link)
                            })}
                        </Flex>
                    </Flex>
                })}
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Thanks)
