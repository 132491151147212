/**
 * 该文件是用于创建一个为Count组件服务的reducer，reducer的本质是一个函数
 * reducer函数会接到两个参数，分别是之前的状态(preState)和动作对象(action)
 */
export default function reducerCount(preState = 0, action) {
    //从action中获取type和data
    const {type, data} = action
    //根据type决定如何处理数据
    switch (type) {
        //加
        case 'increment':
            return preState + data
        //减
        case 'decrement':
            return preState - data
        //初始化
        default:
            return preState
    }
}